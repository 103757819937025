import { shallowRef, onMounted } from 'vue'

export const useDevice = (desktopComponent: any, mobileComponent: any) => {
    // 获取服务端 user-agent
    const headers = useRequestHeaders(['user-agent'])
    const userAgent = headers['user-agent']?.toLowerCase() || ''

    // 设备检测方法
    const checkMobile = () => {
        if (process.server) {
            return /mobile|android|iphone|ipad|phone/i.test(userAgent)
        }
        return window.innerWidth <= 768 || /mobile|android|iphone/i.test(navigator.userAgent)
    }

    // 初始设备类型判断
    const isMobileUserAgent = checkMobile()
    const deviceComponent = shallowRef(isMobileUserAgent ? mobileComponent : desktopComponent)

    // 客户端逻辑
    if (process.client) {
        onMounted(() => {
            const isMobile = checkMobile()
            if (isMobile !== isMobileUserAgent) {
                deviceComponent.value = isMobile ? mobileComponent : desktopComponent
            }

            // 监听窗口大小变化
            window.addEventListener('resize', () => {
                const isMobile = window.innerWidth <= 768
                deviceComponent.value = isMobile ? mobileComponent : desktopComponent
            })
        })
    }

    return {
        deviceComponent,
        isMobile: computed(() => deviceComponent.value === mobileComponent)
    }
}